export default `
<div><p style="margin-top:0pt; margin-bottom:12pt; widows:0; orphans:0; font-size:13pt"><span style="font-family:ArialMT; vertical-align:1pt; color:#1a1718">Annexe 3</span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:16pt"><span style="font-family:ArialMT; font-size:13pt; vertical-align:1pt; color:#1a1718">&nbsp;</span><span style="font-family:Arial-BoldMT; font-weight:bold; color:#313131">e-Gro CONDITIONS D&#x60;UTILISATION </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:9pt"><span style="font-family:ArialMT; color:#1a1718">Le
 présent document (ainsi que les documents auxquels il fait référence) 
vous indique quelles sont les conditions d&#x60;utilisation de l&#x60;application 
logicielle e-Gro de Grodan. Veuillez lire ces Conditions d&#x60;utilisation 
attentivement avant d&#x60;utiliser l&#x60;application logicielle. En utilisant 
l&#x60;application logicielle, vous acceptez d&#x60;être lié par les présentes 
Conditions d&#x60;utilisation. Il est recommandé que vous conserviez une 
copie de ces Conditions d&#x60;utilisation pour pouvoir les consulter 
ultérieurement. Dernière mise à jour : 29 juin 2018. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article</span><span style="font-family:ArialMT">1 Définitions</span><span style="font-family:ArialMT">1.1
 Dans le présent document, « Grodan » et « nous » désignent Rockwool BV,
 qui exerce ses activités sous le nom de Grodan, et qui est une société 
constituée conformément au droit de Pays-Bas inscrite au registre des 
sociétés sous le numéro 13014428, et dont le siège se situe à l&#x60;adresse 
Industrieweg 15, 6045 JG Roermond, Pays-Bas ; « Conditions d&#x60;utilisation
 » désigne les présentes Conditions d&#x60;utilisation e-Gro, qui fixent les 
modalités et conditions d&#x60;utilisation de l&#x60;application logicielle ; « 
Service(s) » désigne le(s) service(s) proposé(s) ou fourni(s) par Grodan
 par l&#x60;intermédiaire de l&#x60;application logicielle ; et « Utilisateur » et
 « vous » désignent une personne physique ou morale qui utilise 
l&#x60;application logicielle. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 2 Applicabilité </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">2.1
 Ces Conditions d&#x60;utilisation s&#x60;appliquent à l&#x60;utilisation de 
l&#x60;application logicielle et sont applicables à l&#x60;exclusion de toutes 
conditions générales utilisées par l&#x60;Utilisateur. Des modalités et 
conditions différentes ne s&#x60;appliqueront que si et dans la mesure où 
elles auront été acceptées de manière distincte par Grodan pour chaque 
accord individuel.</span><span style="font-family:ArialMT">2.2 En 
utilisant l&#x60;application logicielle (ce qui comprend également le 
téléchargement et l&#x60;installation de l&#x60;application logicielle) vous 
acceptez les présentes Conditions d&#x60;utilisation. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">2.3
 Si l&#x60;application logicielle utilise des services de tiers, les 
conditions et modalités et/ou politiques relatives au respect de la vie 
privée de ces tiers peuvent s&#x60;appliquer. Grodan n&#x60;est pas responsable 
des services et/ou politiques en matière de respect de la vie privée des
 tiers en question.</span><span style="font-family:ArialMT">2.4 Nous avons le droit de réviser et de modifier les présentes Conditions d&#x60;utilisation à tout moment. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 3 Votre statut </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">En utilisant la présente application logicielle, vous garantissez que : </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">6.4
 Les données à caractère personnel seront transférées dans des pays 
extérieurs à l&#x60;UE afin de fournir les Services et d&#x60;exploiter 
l&#x60;application logicielle. En utilisant l&#x60;application logicielle et en 
nous fournissant des informations à caractère personnel par 
l&#x60;application logicielle, vous acceptez également le traitement de ces 
données conformément aux présentes Conditions d&#x60;utilisation et à la 
législation applicable en matière de protection des données à caractère 
personnel. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">6.5
 Pour en savoir plus sur les pratiques de Grodan en matière de 
protection de la vie privée, veuillez lire notre Déclaration relative à 
la protection de la vie privée, que vous trouverez sur le présent site 
Internet. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 7 Responsabilité </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.1
 Nous nous efforcerons au mieux de nos capacités de fournir 
l&#x60;application logicielle avec le soin requis. Néanmoins, Grodan ne peut 
pas garantir que l&#x60;application logicielle sera toujours disponible et 
fonctionnera toujours sans interruption, erreur ou défaut, ou que les 
informations contenues dans l&#x60;application logicielle ou fournies par 
Grodan par l&#x60;intermédiaire de l&#x60;application logicielle (« Informations 
») seront complètes, correctes et/ou à jour. Nous ne serons pas 
responsables envers vous ni envers aucun tiers si, pour quelque raison 
que ce soit, l&#x60;application logicielle est indisponible à un moment 
quelconque ou à quelque période que ce soit. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.2
 Toutes les Informations sont fournies par Grodan au moyen 
d&#x60;informations non contraignantes. Grodan ne fait aucune déclaration et 
ne donne aucune garantie d&#x60;aucune sorte, expresse ou implicite, relative
 au caractère complet, à l&#x60;exactitude, ou à la fiabilité ou à la 
disponibilité des Informations. Vous vous fondez sur les Informations à 
vos risques et périls. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.3
 Grodan n&#x60;est pas responsable des Contributions, des informations et 
autres matériels ou communication (ni de l&#x60;exactitude de ceux-ci) que 
l&#x60;Utilisateur ou d&#x60;autres personnes publient ou fournissent par 
l&#x60;intermédiaire de l&#x60;application logicielle. Grodan se réserve le droit 
de retirer (en l&#x60;annonçant ou sans l&#x60;annoncer) des Contributions, 
informations ou autres matériels ou déclarations qui ont été publiés par
 l&#x60;intermédiaire de l&#x60;application logicielle. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.4
 Dans toute la mesure autorisée par le droit applicable, Grodan rejette 
par les présentes toute responsabilité et en aucun cas Grodan ne sera 
responsable de dommages, y compris, sans limitation, le dommage direct, 
indirect ou accessoire, notamment la perte provenant de l&#x60;utilisation ou
 de la capacité d&#x60;utiliser l&#x60;application logicielle, y compris les 
dommages découlant d&#x60;inexactitudes, de conseils, d&#x60;alertes, de 
notifications, d&#x60;omissions ou d&#x60;erreurs de l&#x60;application logicielle 
et/ou des Informations. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.5
 Grodan ne sera pas responsable de l&#x60;impossibilité d&#x60;utiliser 
l&#x60;application logicielle qui est causée par des événements qui échappent
 raisonnablement à son contrôle (un « Cas de force majeure »). Les Cas 
de force majeure comprennent tous les actes, événements, absences de 
survenance d&#x60;un événement, omissions ou accidents qui échappent 
raisonnablement au contrôle et comprennent en particulier (sans 
limitation) : (i) impossibilité de l&#x60;utilisation des télécommunications 
publiques ou privées ou des réseaux d&#x60;électricité ; (ii) la législation,
 la réglementation ou les restrictions, notamment à l&#x60;exportation, de 
tout gouvernement ; (iii) le cyberterrorisme et autres cyber-attaques, 
incidents et de sécurité, incidents de sécurité, pertes de données 
intentionnelles et autres corruptions ou pertes de données et (iv) la 
non fourniture ou le retard dans la fourniture de produits ou de 
services à Grodan par des tiers engagés par Grodan. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.6
 Si Grodan subit des dommages, y compris, sans s&#x60;y limiter, des 
sanctions infligées par les autorités et des plaintes de tiers, dus à 
une violation quelconque de l'article 10 des présentes Conditions 
d&#x60;utilisation et/ou du Code de Conduite Client de Grodan par 
l&#x60;Utilisateur, l&#x60;Utilisateur indemnisera alors totalement Grodan des 
dommages subis. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 8 Droit de propriété intellectuelle </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">8.1
 L&#x60;Utilisateur reconnaît que tous les droits de propriété intellectuelle
 et/ou autres droits similaires portant sur l&#x60;application logicielle 
(sur son contenu et sur sa conception), y compris les images, vidéos et 
clips audio sous-jacents, et les Informations appartiennent à titre 
exclusif à Grodan et/ou ses concédants de licence.</span><span style="font-family:ArialMT">8.2
 L&#x60;Utilisateur veillera à ce que son utilisation de l&#x60;application 
logicielle ne porte en aucune manière préjudice aux droits et/ou au nom 
et à la réputation de Grodan. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 9 Confidentialité </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">9.1
 Le traitement des informations, calculs, conseils et autres résultats 
est effectué sur la base d&#x60;informations fournies par l&#x60;Utilisateur. Le 
résultat du traitement des informations, calculs, conseils et autres 
résultats est strictement confidentiel. Vous ne pouvez en aucun cas 
divulguer tout ou partie de la méthode, les résultats des calculs, 
conseils et autres résultats, ou autoriser leur consultation par des 
tiers, les publier ou autoriser leur publication sans l&#x60;autorisation 
écrite de Grodan.</span><span style="font-family:ArialMT">9.2 En cas de
 violation de l&#x60;obligation indiquée à l&#x60;article 9.1, l&#x60;Utilisateur sera 
immédiatement redevable d&#x60;une pénalité due à Grodan, et exigible par 
celle-ci, d'un montant de 2 500 EUR par violation et de 1 000 EUR par 
journée au cours de laquelle la violation se poursuit, sans préjudice du
 droit de Grodan d&#x60;indemnisation de la perte causée, et sans préjudice 
de son droit d&#x60;exiger l&#x60;exécution des présentes Conditions 
d&#x60;utilisation. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 10 Conformité </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">10.1
 En tant qu&#x60;Utilisateur, vous déclarez et garantissez que vous 
n&#x60;utiliserez la licence, l&#x60;application logicielle et les Services 
fournis par Grodan qu&#x60;aux fins pour lesquelles Grodan fournit la 
licence, l&#x60;application logicielle et les Services à l&#x60;Utilisateur. En 
outre, l&#x60;Utilisateur déclare et garantit que toutes ses activités sont 
conformes à toutes les lois et réglementations applicables. 
L&#x60;Utilisateur n&#x60;est en aucune circonstance autorisé à utiliser la 
licence, l&#x60;application logicielle et les Services fournis par Grodan 
pour des activités illicites. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">10.2
 À première demande de Grodan, l&#x60;Utilisateur fournira toute la 
coopération, les installations et les informations nécessaire à une 
inspection et/ou à un contrôle par Grodan ou par toute tierce partie 
engagée par Grodan pour vérifier que l&#x60;Utilisateur agit en pleine 
conformité avec le présent article 10, le Code de Conduite Client de 
Grodan, et les lois et règlements nationaux.</span><span style="font-family:ArialMT">10.3
 En cas de violation du présent article 10, Grodan est en droit de 
résilier immédiatement la relation commerciale avec l&#x60;Utilisateur, y 
compris tous accords qui auront été conclus et l&#x60;utilisation de 
l&#x60;application logicielle. Grodan a également le droit d&#x60;annuler toute 
commande qui a été passée, même si elle a été explicitement acceptée par
 Grodan. L&#x60;Utilisateur n&#x60;aura droit à aucune indemnisation de la part de
 Grodan en cas de résiliation sur la base du présent article 10.3. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 11 Notifications </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">11.1
 Toutes les notification que vous nous adressez doivent être envoyées 
par e-mail à info@grodan.com ou par lettre recommandée ou exploit 
d&#x60;huissier adressés à Rockwool BV trading as Grodan, Industrieweg 15, 
6045 JG Roermond, Pays-Bas.</span><span style="font-family:ArialMT">11.2
 Nous pouvons vous envoyer des notifications à l&#x60;adresse e-mail ou à 
l&#x60;adresse postale que vous nous avez fournies lors de votre 
enregistrement dans l&#x60;application logicielle. La notification sera 
réputée reçue et correctement signifiée 24 heures (jour ouvrable) après 
l&#x60;envoi de l&#x60;e-mail, immédiatement après la signification de l&#x60;exploit 
d&#x60;huissier, ou trois jours ouvrables après la date de l&#x60;expédition de la
 lettre. Pour prouver la signification de toute notification, il suffira
 de prouver la signification de la réception de la lettre recommandée ou
 d'une copie de la notification de l&#x60;huissier et, dans le cas d&#x60;un e- 
mail, que cet e-mail a été envoyé à l&#x60;adresse indiquée du destinataire </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 12 Cession de droits et d&#x60;obligations </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">12.1
 Vous ne pouvez transférer, céder, grever, sous-traiter ou aliéner de 
quelque autre manière que ce soit vos droits et obligations découlant 
des présentes Conditions d&#x60;utilisation sans l&#x60;accord écrit de Grodan.</span><span style="font-family:ArialMT">12.2
 Nous pouvons à tout moment transférer, céder, grever, sous-traiter ou 
aliéner de toute autre manière nos droits et obligations découlant des 
présentes Conditions d&#x60;utilisation. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 13 Renonciation </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">13.1
 Si nous n&#x60;insistons pas pour une exécution stricte de l&#x60;une quelconque 
des obligations vous incombant en vertu des présentes Conditions 
d&#x60;utilisation, ou si nous n&#x60;exerçons pas l&#x60;un quelconque des droits ou 
recours auxquels nous avons droit en vertu des présentes Conditions 
d&#x60;utilisation, cela ne constitue pas une renonciation à ces droits ou 
recours et ne vous libérera pas de votre obligation de vous conformer à 
ces obligations. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">13.2
 Une renonciation de notre part à nous prévaloir d&#x60;un manquement ne 
constitue pas une renonciation pour des manquements à venir.</span><span style="font-family:ArialMT">13.3
 Aucune renonciation de notre part aux présentes Conditions 
d&#x60;utilisation ne prendra effet si elle n&#x60;indique pas expressément 
constituer une renonciation et ne vous est pas communiquée par écrit 
conformément à l&#x60;article 10. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 14 Dissociabilité </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">14.1
 Si l&#x60;une quelconque des présentes Conditions d&#x60;utilisation est déclarée
 par une autorité compétente invalide, illicite ou non exécutoire dans 
quelque mesure que ce soit, cette condition sera, dans la mesure 
susmentionnée, séparée des autres conditions qui continueront d&#x60;être 
valides dans toute la mesure autorisée par la loi. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 15 Droit applicable et juridiction compétente </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">15.1
 Les présentes Conditions d&#x60;utilisation seront régies par le droit des 
Pays-Bas et interprétées conformément à ce droit, à moins que des règles
 locales impératives ne s&#x60;appliquent.</span><span style="font-family:ArialMT">15.2
 Tous les différends découlant des présentes Conditions d&#x60;utilisation ou
 ayant un rapport avec celles-ci seront soumis à la compétence 
juridictionnelle exclusive des tribunaux néerlandais. En dérogation à 
cette disposition, Grodan sera également à tout moment en droit de 
soumettre un différend ou une demande au tribunal compétent du lieu dans
 lequel l&#x60;Utilisateur a sa résidence, son sièges, ou son lieu effectif 
d&#x60;établissement. Cet article n&#x60;affecte pas vos droits légaux. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">Version, Juin 2018 </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">a) b) c) </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">vous avez au moins 18 ans ; et</span><span style="font-family:ArialMT">si
 vous êtes une personne physique, vous êtes juridiquement capable de 
conclure des contrats contraignants ; si vous utilisez l&#x60;application 
logicielle au nom d&#x60;une personne morale, vous êtes habilité à signer et à
 lier cette </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">entité aux présentes Conditions d&#x60;utilisation. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 4 Licence </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">4.1
 Grodan confère par les présentes à l&#x60;Utilisateur une licence non 
exclusive, incessible, et qui ne peut faire l&#x60;objet d&#x60;une sous-licence, 
pour utiliser l&#x60;application logicielle.</span><span style="font-family:ArialMT">4.2
 Sans l&#x60;accord écrit préalable de Grodan, l&#x60;Utilisateur n&#x60;est pas 
autorisé à mettre l&#x60;application logicielle à la disponibilité de tiers 
(à l&#x60;exception des consultants de l&#x60;Utilisateur), à vendre ou louer 
l&#x60;application logicielle à des tiers, à décompiler l&#x60;application 
logicielle, ou à lui appliquer une ingénierie inverse ou à la modifier, 
et il n&#x60;est pas autorisé à retirer ou à contourner (ou à faire retirer 
ou contourner) des dispositifs techniques destinés à protéger 
l&#x60;application logicielle. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">4.3
 L&#x60;Utilisateur est autorisé à accorder à ses employés un accès à 
l&#x60;application logicielle, à condition que cet accès soit toujours placé 
sous le contrôle de l&#x60;Utilisateur. Toutes les données à caractère 
personnel soumises à l&#x60;application logicielle sont considérées comme 
étant des données de l&#x60;Utilisateur.</span><span style="font-family:ArialMT">4.4
 Grodan a le droit à tout moment de (i) interrompre l&#x60;application 
logicielle, de manière provisoire ou définitive ; (ii) apporter des 
modifications procédurales et/ou techniques et/ou des améliorations à 
l&#x60;application logicielle ; (iii) modifier ou retirer des données ou des 
informations ; (iv) restreindre l&#x60;utilisation de l&#x60;application 
logicielle ou de certaines fonctionnalités ; et/ou (v) refuser à 
l&#x60;Utilisateur l&#x60;accès et/ou l&#x60;utilisation de tout ou partie de 
&#x60;application logicielle, provisoirement ou de manière permanente, et/ou 
résilier la licence. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">4.5 Grodan a le droit d&#x60;indexer ses prix une fois par an.</span><span style="font-family:ArialMT">4.6
 En plus de l&#x60;article 4.5, Grodan est en droit de modifier les prix si 
les facteurs de détermination de coûts, notamment les impôts, accises, 
droit à l&#x60;importation, taux de change, salaires, prix des marchandises 
et/ou des services (qu&#x60;ils soient ou non obtenus de tiers par Grodan) 
changent après l&#x60;offre et/ou la conclusion d&#x60;un contrat. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">Article 5 Utilisation de l&#x60;application logicielle</span><span style="font-family:ArialMT">5.1
 Toute utilisation de l&#x60;application logicielle est à votre propre risque
 et sous votre responsabilité.5.2 Vous ne pouvez utiliser l&#x60;application
 logicielle qu&#x60;à des fins légales. Vous ne pouvez pas utiliser 
l&#x60;application logicielle (i) d&#x60;une manière qui viole la législation ou 
la réglementation locale, nationale ou internationale ; ou (ii) d&#x60;une 
manière qui soit illicite ou frauduleuse, ou qui ait une intention, un 
objectif ou un effet illicites ou frauduleux.5.3 Après que 
l&#x60;Utilisateur a téléchargé l&#x60;application logicielle, l&#x60;Utilisateur peut 
s&#x60;enregistrer au moyen de l&#x60;application logicielle. Seuls les 
enregistrements effectués de manière complète et sincère seront pris en 
compte par Grodan. En cas de modification des informations détaillées de
 l&#x60;enregistrement ou d&#x60;autres données pertinentes, l&#x60;Utilisateur devra 
mettre ces données à jour par l&#x60;intermédiaire de l&#x60;application 
logicielle.5.4 Si vous choisissez un code d&#x60;identification 
d&#x60;utilisateur, un mot de passe ou toute autre information en tant 
qu&#x60;élément de nos procédures de sécurité, ou si ces code, mot de passe 
et information vous sont communiqués, vous devez traiter ces 
informations comme étant confidentielles, et vous ne les divulguerez pas
 à des tiers. Nous sommes autorisés à tout moment à désactiver tout code
 d&#x60;identification d&#x60;utilisateur ou tout mot de passe, que vous l&#x60;ayez 
choisi ou que nous vous l&#x60;ayons attribué si, selon nous, vous n&#x60;avez pas
 respecté les présentes Conditions d&#x60;utilisation.5.5 Afin de permettre 
l&#x60;utilisation de l&#x60;application logicielle, l&#x60;Utilisateur fournira à son 
propre compte une connexion nécessaire pour avoir accès à l&#x60;application 
logicielle. Une connexion comprend les équipements nécessaires, le 
logiciel système et la connexion (Internet) mais ne s&#x60;y limite pas. 
Grodan n&#x60;est pas responsable des erreurs de connexion faites par les 
fournisseurs de services de connexions aux clients.5.6 Si l&#x60;Utilisateur
 fournit des informations, des données et/ou d&#x60;autres contenus (« 
Contribution(s) ») par l&#x60;intermédiaire de l&#x60;application logicielle, 
l&#x60;Utilisateur garantit qu&#x60;il est le propriétaire légitime de la 
Contribution et l&#x60;Utilisateur garantit que ses Contributions ne 
contiennent aucune information illicite. L&#x60;application logicielle ne 
fonctionnera pleinement que lorsque les Contributions auront été 
fournies et que dès lors les Contributions fournies seront complètes, 
correctes et à jour.5.7 L&#x60;Utilisateur accorde par les présentes à 
Grodan une licence non-exclusive, perpétuelle, gratuite, pouvant faire 
l&#x60;objet d&#x60;une sous-licence et cessible, pour utiliser les Contributions 
et reconnaît que Grodan est en droit d&#x60;utiliser et de partager des 
informations commerciales globales, y compris les Contributions, ne vous
 identifiant pas spécifiquement ou n&#x60;identifiant pas spécifiquement la 
personne morale au nom de laquelle vous utilisez l&#x60;application 
logicielle (i) pour permettre à l&#x60;Utilisateur d&#x60;utiliser l&#x60;application 
logicielle ; (ii) pour fournir et améliorer les Services de Grodan au 
moyen de l&#x60;application logicielle ; et/ou (iii) pour mettre à jour et 
continuer de développer l&#x60;application logicielle.5.8 L&#x60;Utilisateur 
reconnaît et accepte que la Contribution peut être cédée et utilisée par
 des tiers engagés par Grodan, y compris le fournisseur de cloud de 
Grodan, afin d&#x60;exploiter et/ou de gérer l&#x60;application logicielle.5.9 
Grodan déterminera, à sa discrétion, s&#x60;il y a eu violation des présentes
 Conditions d&#x60;utilisation. Si une violation des présentes Conditions 
d&#x60;utilisation s&#x60;est produite, nous pourrons entreprendre les actions que
 nous jugerons appropriées. 5.10 L&#x60;utilisation de l&#x60;application 
logicielle n&#x60;est autorisée que dans le cas où l&#x60;Utilisateur a rempli et 
continue de remplir ses obligations de paiement. Si un paiement n&#x60;est 
pas reçu dans les délais, des intérêts mensuels de 1 % sur le montant 
dû, calculés à partir de l&#x60;échéance et jusqu&#x60;au jour de paiement, 
celui-ci compris, les parties de mois étant considérées comme des mois 
entiers, sont dus par l&#x60;Utilisateur sur le montant de la facture, sans 
notification de manquement. Tous les frais relatifs au recouvrement 
seront facturés à l&#x60;Utilisateur. Les frais de recouvrement 
extrajudiciaires s&#x60;élèveront à au moins 15% du montant à recouvrir, avec
 un minimum de 200 EUR.5.11 En vous fournissant un accès à 
l&#x60;application logicielle, Grodan ne prétend aucunement aider 
l&#x60;Utilisateur à violer les lois ou règlements de l&#x60;État de l&#x60;Utilisateur
 ou du pays de son domicile. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">Article 6 Respect de la vie privée </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">6.1
 Nous pouvons utiliser vos données à caractère personnel, par exemple 
les informations que vous aurez fournies par l&#x60;application logicielle, 
en utilisant l&#x60;application logicielle ou de toute autre manière, les 
informations relatives à votre utilisation de l&#x60;application logicielle 
(informations de connexion) et les informations techniques relatives à 
votre appareil mobile. Ces informations à caractère personnel seront 
collectées et utilisées pour garantir le fonctionnement approprié de 
l&#x60;application logicielle, pour vous fournir les Services, pour améliorer
 l&#x60;application logicielle et/ou les Services, pour améliorer 
l&#x60;application logicielle au moyen de l&#x60;apprentissage machine, et pour 
vous contacter ou vous fournir des informations pertinentes concernant 
l&#x60;application logicielle, les Services, et l&#x60;utilisation que vous en 
faites. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">6.2
 Si et dans la mesure où nous traitons vos données à caractère 
personnel, ces données seront traitées conformément à la législation sur
 la protection des données à caractère personnel applicable. En 
utilisant l&#x60;application logicielle et en nous fournissant des 
informations à caractère personnel par l&#x60;intermédiaire de l&#x60;application 
logicielle, vous acceptez le traitement de ces données conformément aux 
présentes Conditions d&#x60;utilisation et à la législation applicable en 
matière de protection des données à caractère personnel.</span><span style="font-family:ArialMT">6.3
 Toutes les données à caractère personnel que vous avez fournies par 
l&#x60;intermédiaire de l&#x60;application logicielle seront utilisées, avec les 
informations à caractère personnel des autres utilisateurs, pour 
améliorer l&#x60;application logicielle. Toutes les données utilisées dans le
 cadre du présent article 6.3 seront anonymisées et ne peuvent donc être
 considérées comme de données à caractère personnel au sens de la 
législation sur la protection des données à caractère personnel. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">Version, Juin 2018 </span></p></div>
`
